// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
    apiKey: "AIzaSyB8zlMjjFNhJslqoq3p5nn8x0ufgkbau18",
    authDomain: "personal-website-dbf00.firebaseapp.com",
    projectId: "personal-website-dbf00",
    storageBucket: "personal-website-dbf00.appspot.com",
    messagingSenderId: "829517346775",
    appId: "1:829517346775:web:04c921a4a0a44710ac7d92",
    measurementId: "G-5XY90Q19LX"
  };

  export default firebaseConfig;